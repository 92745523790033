@import "../assets/sass/variable.scss";
.citizenship-content {
	padding: 15px;
	display: table-cell;
	vertical-align: middle;
	.title {
		text-transform: uppercase;
		color: $yellow-color;
		font-weight: bold;
		font-size: 24px;
		margin: 20px 0;
	}
	.description {
		color: $black-color;
		margin-bottom: 30px;
		text-align: left;
	}
	.form {
		min-height: unset;
		.checkbox-group {
			text-align: center;
			display: block;
		}
	}
	.divider {
	    display: flex;
	    align-items: center;
	    text-align: center;
	    margin: 30px 0;
	    padding: 0 130px;
	    text-transform: uppercase;
	    color: $black-color;
	    font-weight: 200;
		&:before, &:after {
		    content: '';
		    flex: 1;
		    border-bottom: 1px solid #e4e4e4;
		    border-bottom: 1px solid #e4e4e4;
		}
		&:before {
			margin-right: 15px;
		}
		&:after {
			margin-left: 15px;
		}
		.img {
			max-width: 100%;
			height: auto;
			width: 20px;
		}
	}
	.info {
		color: $black-color;
	}
}

.homepageModalInfo {
	background: #034f89;
	color: #fff;
	position: relative;
}

.homepageModalInfoFooter {
	padding: 20px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid #ffffff;
}

.ModalBodyInner {
	padding: 15px;
}

// MOBILE
@media (max-width: 991px) {
	.citizenship-content {
		padding: 0;
		.description {
			padding: 0;
		}
	}
}

@media (max-width:480px) {
	.ModalBodyInner {
		p {
			font-size: 16px;
		}
	}
}

// tablet
@media (max-width:1024px) {
	.ModalBodyInner {
		p {
			font-size: 16px;
		}
	}
}