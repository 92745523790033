@import "variable";
.title {

}
.form {
	.form-group {
		text-align: left;
		margin-bottom: 1.5rem;
		&.valid {
			.form-label {
				color: $blue-color;
			}
			.form-input {
				&:focus, &:hover {
					border-color: $blue-color;
				}
				border-color: $blue-color;
			}
		}
		.form-label {
			font-size: 14px;
			font-weight: bold;
			color: $black-color;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin: 0;
			transition: all 0.5s;
			.required {
				color: $yellow-color;
				display: inline-block;
			}
			.info {
				text-transform: none;
				color: $black-color;
				letter-spacing: normal;
				display: inline-block;
				&.inline {
					color: $blue-color;
				}
			}
		}
		.form-input {
			width: 100%;
			font-size: 16px;
			border: 0;
			border-radius: 0;
			border-bottom: 2px solid #c4c4c4;
			background-color: transparent;
			color: #2e2e2e;
			padding: 0;
			background: unset;
			transition: all 0.5s;
			&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			  -webkit-appearance: none;
			  margin: 0;
			}
			&[type=number] {
				-moz-appearance: textfield;
			}
			&[type=date] {
				&::-webkit-datetime-edit-text {
					font-family: 'Muli';
				}
			}
			&:focus, &:hover {
				box-shadow: none;
				border-color: #c4c4c4;
			}
			&.is-valid {
				border-color: $blue-color;
				&:focus {
					border-color: $blue-color;
				}	
			}
			&.is-invalid {
				border-color: #dc3545;
				&:focus {
					border-color: #dc3545;
				}
			}
			&.is-invalid ~ label.form-label {
				background-color: red;
			}
			&:invalid, &:valid {
				background: unset;
				padding: 5px 0;
				&:focus {
					box-shadow: unset;
				}
			}
		}
		.group {
			.area-code {
				width: 15%;
				display: inline-block;
			}
			.field-number {
				width: calc(85% - 20px);
				display: inline-block;
				margin-left: 20px;
			}
			.work-period-field {
				width: calc(30% - 40px);
				display: inline-block;
				margin-right: 20px;
			}
		}
		// input:read-only {
		// 	border: 0;
		// }
		// input:-moz-read-only { 
		// 	border: 0;
		// }
		.radio-group {
			padding: 0;
			.form-radio-label {
				text-transform: none;
				font-size: 16px;
				position: relative;
				cursor: pointer;
				padding-left: 15px;
				margin: 0;
				color: $black-color;
				margin: 10px 15px 0 0;
				span.checkmark {
					content: "";
					position: absolute;
					width: 12px;
					height: 12px;
					left: 0;
					border: 1px solid $black-color;
					border-radius: 50%;
					top: 55%;
					transform: translateY(-50%);
				}
				.form-radio {
					opacity: 0;
					position: absolute;
					left: 0;
					cursor: pointer;
					&:checked ~ span.checkmark{
						border-color: $blue-color;
						&:after {
						    content: "";
						    position: absolute;
						    width: 3px;
						    height: 3px;
						    border-radius: 50%;
						    left: 50%;
						    transform: translate(-50%, -50%);
						    top: 50%;
						    background-color: $blue-color;
						}
					}
					&.is-invalid ~ span.checkmark {
						border-color: #dc3545;
					}
				}
			}
		}

		.check-group {
			padding: 0;
			.form-check-label {
				text-transform: none;
				font-size: 16px;
				position: relative;
				cursor: pointer;
				padding-left: 20px;
				margin: 0;
				color: $black-color;
				margin: 10px 15px 0 0;
				span.checkmark {
					content: "";
					position: absolute;
					width: 12px;
					height: 12px;
					left: 0;
					border: 1px solid $black-color;
					top: 55%;
					transform: translateY(-50%);
				}
				.wrap-icon {
					position: absolute;
					left: 0;
					border: 1px solid $black-color;
					top: 55%;
					transform: translateY(-50%);
					padding: 2px;
					width: 12px;
					height: 12px;
					.icon {
						position: absolute;
					    top: 50%;
					    left: 0;
					    transform: translateY(-50%);
					    display: inline-block;
					    font-size: 10px;
					    display: none;
					}
				}
				.form-check {
					opacity: 0;
					position: absolute;
					left: 0;
					cursor: pointer;
					&:checked ~ span.checkmark{
						border-color: $blue-color;
						&:after {
						    content: "";
						    position: absolute;
						    width: 3px;
						    height: 3px;
						    left: 50%;
						    transform: translate(-50%, -50%);
						    top: 50%;
					    	background-color: $blue-color;
						}
					}
					&:checked ~ .wrap-icon{
						border-color: $blue-color;
						.icon {
							display: inline-block;
							color: $blue-color;
						}
					}
					&.is-invalid ~ span.checkmark {
						border-color: #dc3545;
					}
				}
			}
		}

		.wrap-signature {
			position: relative;
			.sigCanvas {
				display: block;
				border: 1px solid #c4c4c4;
				border-radius: 5px;
			}
			.navigate-signature {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				.choose-file {
					right: 0;
				}
			}
		}

		.divider-fileinput {
		    align-items: center;
		    text-align: center;
		    text-transform: uppercase;
		    color: #6f6f6f;
		    font-weight: 200;
		    padding: 0 5px;
		    font-size: 12px;
		    padding: 10px 0;
		}

		.wrap-example {
			display: inline-block;
			&.sign {
				width: 350px;
				.info {
					strong {
						text-transform: uppercase;
					}
					text-align: left;
					margin-top: 15px;
					span {
						margin-bottom: 5px;
						display: block;
					}
				}
			}
			.info {
				text-align: center;
				color: $black-color;
				font-size: 14px;
			}
		}

		.wrap-input-file {
			width: 100%;
		    vertical-align: middle;
		    text-align: center;
		    border: 1px solid $blue-color;
		    border-style: dashed;
		    border-radius: 3px;
		    border-width: 2px;
		    margin-top: 10px;
		    min-height: 260px;
		    position: relative;
	    	padding: 0.25rem;
			.image-processing {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: #ffff;
				color: #0172ba;
				width: 100%;
				height: 100%;
				z-index: 999999999;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.hide {
				display: none;
			}			
	    	&.img-exists {
	    		border-style: solid;
	    	}
		    .full-side {    
		    	position: absolute;
			    left: 50%;
			    top: 50%;
			    transform: translate(-50%, -50%);
			    width: 100%;
			    padding: 0 10px;
			    .half-side {
			    	display: inline-block;
			    	width: 50%;
			    	vertical-align: middle;
			    }
		    }
		    .wrap-img-upload {
		    	cursor: pointer;
		    	position: relative;
		    	.img-upload {
			    	max-width: 100%;
			    	height: 250px;
		    	}
		    	.wrap-overlay-img-upload {
		    		display: block;
				    opacity: 0;
				    position: absolute;
				    left: 50%;
				    top: 50%;
				    transform: translate(-50%, -50%);
				    z-index: 1;
	    			transition: all 0.5s;
		    	}
		    	&:after {
	    			content: "";
	    			position: absolute;
	    			left: 0;
	    			top: 0;
	    			width: 100%;
	    			height: 100%;
	    			transition: all 0.5s;
		    	}
		    	&:hover {
		    		&:after {
		    			background-color: #00000075;
		    		}
		    		.wrap-overlay-img-upload {
		    			display: block;
		    			opacity: 1;
		    		}
		    	}
		    }
			.direct-selfie-instruction-wrapper {
				padding: 5px;
				p {
					font-size: 12px;
    				margin: 0 0 0 25px;
    				padding: 0;
    				text-align: left;
				}
				ol {
					margin: 0;
    				font-size: 10px;
    				text-align: left;
				}
			}
			.direct-selfie-capturebtn-wrapper {
				margin: 15px 0;
			}
		}
	}

	.track-section, .summary {
		text-align: left;
		padding: 0 25px;
		.table-track {
			tr {
				td {
					border: 0;
					border: 2px solid #c4c4c4;
					.icon {
						width: 25px;
						height: 25px;
						border: 1px solid #c4c4c4;
						border-radius: 50%;
						color: #c4c4c4;
						padding: 3px;
						vertical-align: middle;
						margin-right: 5px;
						&.done {
							color: $green-color;
						}
						&.process {
							color: $orange-color;
						}
						&.failed {
							color: $red-color;
						}
					}
				}
			}
		}
		.table-summary {
			margin-bottom: 2px;
			tr {
				td {
					border: 0;
					width: calc(100% / 3);
					text-align: center;
				}
			}
		}
	}

	.disclaimer {
		border: 1px solid #c4c4c4;
		max-height: 400px;
		overflow-y: auto;
		border-radius: 5px;
		padding: 5px;
		text-align: left;
		font-size: 14px;
		.pernyataan {
			margin: 25px 0;
			text-align: justify;
			font-weight: bold;
		}
	}
	.information-bca {
	    text-align: left;
	    color: $black-color;
	  	strong {
	  		text-transform: uppercase;
	  	}
	    padding-left: 15px;
	    font-size: 12px;
	    margin-bottom: 15px;
	    p {
	    	margin-bottom: 5px;
	    }
	}
}
.invalid-feedback {
	display: block;
	width: 100%;
	font-family: 'Muli';
    margin-top: 0.25rem;
    color: #dc3545;
}

.modal-webcam {
	.modal-header {
		h3 {
			font-size: 21px;
			font-weight: bold;
		}
	}
	
	.modal-body {
		p {
			position: relative;
		}
	}
}

.photo-guide {
	img {
		width: 100%;
	}
}

.camera-container {
	position: relative;
	width: 100%;
	.webcam {
		width: 100%;
		height: auto;
		position: relative;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	  }
	  
	  .circle {
		// width: 330px;
		// height: 375px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
	  }
}

// .link_download,.not_available {
// 	font-size: 12px;
// 	display: inline-block;
// 	font-style: italic;	
// 	color: $blue-color;
// 	text-decoration: none;
// 	padding-bottom: 1px;
// 	border-bottom: 1px solid transparent;
// 	transition: all 0.5s;
// 	border-color: $blue-color;
// 	padding: 0;
// 	&:hover {
// 		text-decoration: none;
// 		border-color: $yellow-color;
// 		color: $yellow-color;
// 	}
// 	&.not_yet {
// 		border-bottom: 0;
// 		border-color: $black-color;
// 		color: $black-color;
// 		&:hover {
// 			border-color: $black-color;
// 			color: $black-color;
// 		}
// 	}
// }

// .not_available {
//     color: #dc3545;
//     border-bottom: 0;
// 	&:hover {
// 		border-color: #dc3545;
// 		color: #dc3545;
// 	}
// }

@media (max-width: 991px) {
	.form {
		.form-group {
			.wrap-example.sign {
				width: 100%;
			}
		}
	}
}

@media (max-width: 485px) {
	.form {
		.form-group {
			.wrap-input-file {
				.full-side.example {
					position: unset;
					left: unset;
					right: unset;
					transform: unset;
					padding: 15px;
					.half-side {
						width: 100%;
						&:nth-child(2) {
							margin-top: 25px;
						}
					}
				}
			}
		}
	}
}